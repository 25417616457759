import { useParams } from 'react-router-dom-v5-compat';
import { FormattedMessage } from 'react-intl';

import { Empty, Loadable } from 'modules/ui';

import { useFetchSecret } from '../../hooks/useFetchSecret';
import { SecretDetail } from '../SecretDetail';
import { SharingModalProvider } from './SharingModalProvider';
import { SecretDetailProvider } from './SecretDetailProvider';

import type { Secret } from '../../types';

export const SecretDetailPage = () => {
    const { id } = useParams<{
        id: Secret['id'];
    }>();

    if (!id) {
        throw new Error('Secret ID is required');
    }

    const { showLoader, hasSecret } = useFetchSecret(id);

    if (!showLoader && !hasSecret) return <Empty text={<FormattedMessage id="secret.detail.empty" />} />;

    return (
        <Loadable showLoader={showLoader}>
            <SecretDetailProvider id={id}>
                <SharingModalProvider>
                    <SecretDetail />
                </SharingModalProvider>
            </SecretDetailProvider>
        </Loadable>
    );
};
