import { combineRules, type CSSObject } from 'fela';

import type { Theme, TRuleWithTheme } from 'styles/theme';

export enum LinkType {
    ROUTER = 'router',
    NATIVE = 'native',
}

export enum LinkSize {
    BIG = 'big',
    REGULAR = 'regular',
    SMALL = 'small',
}

export const SIZE_STYLE = {
    [LinkSize.BIG]: {
        fontSize: '1rem', // 16px
        fontWeight: 600,
        lineHeight: '1.5rem', // 24px
        letterSpacing: '0.05em', // 0.8px
    },
    [LinkSize.REGULAR]: {
        fontSize: '0.875rem', // 14px
        fontWeight: 600,
        lineHeight: '1.5rem', // 24px
        letterSpacing: '0.05em', // 0.7px
    },
    [LinkSize.SMALL]: {
        fontSize: '0.75rem', // 12px
        fontWeight: 600,
        lineHeight: '1.1875rem', // 19px
        letterSpacing: '0.0583em', // ~ 0.7px
    },
} as const satisfies {
    [LinkSize.SMALL]: CSSObject;
    [LinkSize.REGULAR]: CSSObject;
    [LinkSize.BIG]: CSSObject;
};

export const container: TRuleWithTheme<{
    color?: keyof Theme['colors'];
    size?: `${LinkSize}`;
    inheritColor?: boolean;
}> = ({ theme, size, color = theme.colors.blue, inheritColor }) => ({
    ...(size && SIZE_STYLE[size]),

    color: inheritColor ? 'inherit' : theme.colors[color],

    ':hover': {
        textDecoration: 'none',
        color: inheritColor ? 'inherit' : theme.colors[color],
    },
});

export const externalLink: TRuleWithTheme = combineRules(container, ({ theme }) => ({
    textDecoration: 'underline',
}));
