import { useFela } from 'react-fela';

import { Heading } from 'modules/ui';
import { useBreakpoint } from 'hooks/useBreakpoint';

import type { Secret } from '../../../../types';

import { SecretTypeIcon } from '../../../SecretTypeIcon';
import { SecretItemTags } from '../SecretItemTags';
import { SharingIndicator } from '../SharingIndicator';
import { useSharingIndicatorVisibility } from '../../../../hooks';

import * as felaRules from './SecretItemInfo.rules';
import { SecretType } from '../../../../constants';
import { CopySecretValueSections } from './CopySecretValueSections';
import { WebsiteFavicon } from '../../../WebsiteFavicon';

export type SecretItemInfoProps = Secret;

export const SecretItemInfo = (props: SecretItemInfoProps) => {
    const { name, type, tags, share, userPermissions } = props;

    const { css } = useFela();
    const isDesktop = useBreakpoint('sm');

    const { getSharingIndicatorVisibility } = useSharingIndicatorVisibility();
    const shouldShowSharingIndicator = getSharingIndicatorVisibility({ share, userPermissions });

    return (
        <div className={css(felaRules.container)}>
            <div className={css(felaRules.topRowContainer)}>
                <div className={css(felaRules.titleWithFavicon)}>
                    {props.type === SecretType.Password && props.favicon ? (
                        <WebsiteFavicon base64Favicon={props.favicon} name={name} width={24} height={24} />
                    ) : (
                        <SecretTypeIcon type={type} width={24} height={24} />
                    )}
                    <Heading color="foregroundsSecondary">{name}</Heading>
                </div>

                {isDesktop && <SecretItemTags tags={tags} />}
            </div>

            <div className={css(felaRules.contentContainer)}>
                <div className={css(felaRules.properties)}>
                    <CopySecretValueSections secret={props} />

                    {!isDesktop && <SecretItemTags tags={tags} />}
                </div>
                {shouldShowSharingIndicator && <SharingIndicator />}
            </div>
        </div>
    );
};
