import { useFela } from 'react-fela';

import { useIsAdmin } from 'modules/auth';

import { SecretNameWithIcon } from '../SecretNameWithIcon';
import { SecretHeader } from '../SecretHeader';
import { SecretDetailAlerts } from './SecretDetailAlerts';
import { DetailButtons } from './DetailButtons';
import { CreatorTag } from './CreatorTag';
import { useSecretDetailContext } from '../SecretDetailPage';
import { SecretDetailSections } from '../SecretDetailSections';
import { UpdatedAndCreatedDates } from './UpdatedAndCreatedDates';

import * as felaRules from './SecretDetail.rules';

export const SecretDetail = () => {
    const secret = useSecretDetailContext();
    const isAdmin = useIsAdmin();

    const { css } = useFela({ isAdmin });

    const { createdBy } = secret;

    return (
        <div className={css(felaRules.pageContainer)}>
            <SecretHeader>
                <DetailButtons />
            </SecretHeader>
            <SecretDetailAlerts />
            <main className={css(felaRules.contentContainer)}>
                <SecretNameWithIcon secret={secret} />
                {createdBy ? <CreatorTag creator={createdBy} /> : null}
                <SecretDetailSections />
                <UpdatedAndCreatedDates />
            </main>
        </div>
    );
};
