import { FormattedMessage } from 'react-intl';

import { useController, useFormContext } from 'react-hook-form';

import { FormField, InfoLabel, Label, TextInput } from 'modules/forms';
import { Spacer } from 'modules/ui';

import type { SecretFormValues } from '../../../schemas';

import { Fields } from '../../../constants';
import { SecretTOTP } from '../../SecretTOTP';
import { TOTPFromQRCodeButton, isTOTPError } from '../../TOTPFromQRCodeButton';

const name = Fields.TOTP;

export const TOTPField = () => {
    const {
        field: { value, onBlur, onChange },
    } = useController({
        name,
    });

    const { watch, formState, setValue } = useFormContext<SecretFormValues>();

    const hasTOTP = watch(Fields.HAS_TOTP);

    return (
        <div>
            <FormField
                htmlFor={name}
                label={
                    <Label>
                        <FormattedMessage id="secret.TOTP.label" />
                    </Label>
                }
                name={name}
            >
                {hasTOTP ? (
                    <SecretTOTP
                        onRemove={() => {
                            onChange(null);
                            setValue(Fields.HAS_TOTP, false);
                        }}
                    />
                ) : (
                    <>
                        <TextInput
                            data-testid="totp-input"
                            id={name}
                            value={isTOTPError(value) ? null : value}
                            onBlur={onBlur}
                            disabled={formState.isSubmitting}
                            suffix={
                                <TOTPFromQRCodeButton
                                    onChange={qrCodeContent => {
                                        onChange({ target: { value: qrCodeContent } });
                                        onBlur();
                                    }}
                                />
                            }
                            onChange={e => {
                                if (e.target.value.trim().length === 0) {
                                    onChange(null);
                                } else {
                                    onChange(e);
                                }
                            }}
                        />
                    </>
                )}
            </FormField>
            <Spacer space={0.62} />
            <InfoLabel>
                {hasTOTP ? (
                    <FormattedMessage id="secret.TOTP.help.hasTOTP" />
                ) : (
                    <FormattedMessage id="secret.TOTP.help" />
                )}
            </InfoLabel>
        </div>
    );
};
