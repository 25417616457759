import { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom-v5-compat';

import { StyleProvider } from 'styles/components/StyleProvider';

import { config } from 'config/config';
import { IS_PRODUCTION } from 'constants/index';

import { DemoHead } from 'modules/demo';
import { Messages } from 'modules/messages';
import { Authorized } from 'modules/auth';
import { ShareSecretDetailPage } from 'modules/secrets';

import { AuthorizedApplication } from '../AuthorizedApplication';
import { Cta } from '../Cta';

const PWAiOSPrompt = lazy(() =>
    import('../iOSPwaPrompt').then(({ PWAiOSPrompt }) => ({
        default: PWAiOSPrompt,
    })),
);

export const Application = () => (
    <StyleProvider>
        {config.demo.enable && <DemoHead />}
        <Routes>
            <Route path={config.routes.share} element={<ShareSecretDetailPage />} />
            <Route
                path="*"
                element={
                    <>
                        <Messages />
                        <Authorized>
                            <AuthorizedApplication />
                        </Authorized>
                    </>
                }
            />
        </Routes>
        {config.serviceWorker && IS_PRODUCTION && (
            <Suspense fallback={null}>
                <PWAiOSPrompt />
            </Suspense>
        )}

        {config.demo.enable && <Cta />}
    </StyleProvider>
);
