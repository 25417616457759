import { z } from 'zod';

import type { MessageKey } from 'modules/localizations/translations/types';

import { isValidTOTP } from '../utilities';
import { isTOTPError, type TOTPError } from '../components/TOTPFromQRCodeButton';
import { Fields, SecretType } from '../constants';
import { basicSecretFormSchema } from './basicSecretFormSchema';

export const passwordSecretFormSchema = basicSecretFormSchema.merge(
    z.object({
        [Fields.TYPE]: z.literal(SecretType.Password),
        [Fields.USERNAME]: z.string().optional(),
        [Fields.WEB]: z.string().optional(),
        [Fields.PASSWORD]: z.string().optional(),
        [Fields.TOTP]: z
            .union([
                z.string().refine(val => isValidTOTP(val), {
                    message: 'form.error.TOTP.general' satisfies MessageKey,
                }),
                z
                    .custom<TOTPError>(val => isTOTPError(val))
                    .superRefine((val, ctx) => {
                        ctx.addIssue({
                            code: z.ZodIssueCode.custom,
                            message: `form.error.TOTP.qrcode.${val.code}` satisfies MessageKey,
                        });
                    }),
            ])
            .nullish(),
        [Fields.HAS_TOTP]: z.boolean().optional(),
    }),
);
