import type { TRuleWithTheme } from 'styles/theme';

import type { ButtonProps } from './Button';

import { SIZE_STYLE } from '../typography/Link/Link.rules';

const buttonHeight = 2.25;
const buttonSmHeight = 1.5;
const buttonHorizontalPadding = 1.3125;

export enum ButtonType {
    LINK = 'link',
    DEFAULT = 'default',
    PRIMARY = 'primary',
    GHOST = 'ghost',
    DASHED = 'dashed',
    TEXT = 'text',
}

export const link: TRuleWithTheme<{ color: ButtonProps['color'] }> = ({ theme, color }) => ({
    '&.ant-btn-link': {
        ...SIZE_STYLE['regular'],

        height: SIZE_STYLE['regular'].lineHeight,

        padding: 0,

        ...(color && { color: theme.colors[color] || color }),

        border: 'none',
        borderRadius: 0,
        boxShadow: 'none',

        ':hover': {
            textDecoration: 'none',
        },
        ':focus': {
            textDecoration: 'none',
        },
    },
    '&.ant-btn-link > span': {
        display: 'inline',
    },
    '&.ant-btn-link[disabled]': {
        color: theme.colors.brownGrey3,
        textDecoration: 'underline',
    },
    '&.ant-btn-link.ant-btn-sm': { ...SIZE_STYLE['small'], height: SIZE_STYLE['small'].lineHeight },
    '&.ant-btn-link.ant-btn-lg': { ...SIZE_STYLE['big'], height: SIZE_STYLE['big'].lineHeight },
});

export const text: TRuleWithTheme<{ color: ButtonProps['color'] }> = ({ theme, color }) => ({
    '&.ant-btn-text': {
        ...SIZE_STYLE['regular'],

        display: 'flex',
        alignItems: 'center',
        gap: '0.40625rem',

        height: SIZE_STYLE['regular'].lineHeight,

        padding: 0,

        ...(color && { color: theme.colors[color] || color }),
        fontWeight: 600,

        border: 'none',
        borderRadius: 0,
        boxShadow: 'none',

        ':not(:disabled):not(.ant-btn-disabled):hover': {
            background: 'none',
        },
    },
    '&.ant-btn-text > span': {
        display: 'inline',
    },
    '&.ant-btn-text[disabled]': {
        color: theme.colors.brownGrey3,
    },
    '&.ant-btn-text.ant-btn-lg': {
        ...SIZE_STYLE['big'],
        height: SIZE_STYLE['big'].lineHeight,
        padding: '0.5rem 0.375rem',
    },
    '&.ant-btn-text.ant-btn-sm': {
        ...SIZE_STYLE['small'],
        height: SIZE_STYLE['small'].lineHeight,
        padding: 0,
    },
});

export const container: TRuleWithTheme = ({ theme }) => {
    const disabledStyle = {
        color: theme.colors.brownGrey3,
        borderColor: theme.colors.brownGrey3,
        backgroundColor: theme.colors.white,

        cursor: 'default',
    };

    const primaryDisabledStyle = {
        color: theme.colors.brownGrey2,
        borderColor: theme.colors.lightGrey3,
        backgroundColor: theme.colors.lightGrey3,

        cursor: 'default',
    };

    const buttonStyle = {
        '&.ant-btn': {
            ...SIZE_STYLE['regular'],

            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',

            height: `${buttonHeight}rem`,
            padding: `0 ${buttonHorizontalPadding}rem`,

            fontFamily: theme.fontFamily,
            fontSize: '1rem',
            lineHeight: '1.5rem',
            letterSpacing: '0.0375em',

            color: theme.colors.black,
            borderColor: theme.colors.black,
            backgroundColor: theme.colors.white,

            boxShadow: 'none',
            transition: 'none',
        },
        '&.ant-btn.ant-btn-sm': {
            ...SIZE_STYLE['small'],

            height: `${buttonSmHeight}rem`,

            padding: '0.5rem 0.75rem',

            lineHeight: '1rem',
        },
        '&.ant-btn.ant-btn-lg': {
            ...SIZE_STYLE['big'],
            padding: '0.5rem 1rem',
        },
        '&.ant-btn .anticon': {
            marginRight: '.375rem',
        },
        '&.ant-btn .Icon': {
            flexShrink: 0,
        },

        '&.ant-btn > .Icon:last-child ~ span': {
            position: 'relative',
            top: '1px',
        },
        '&.ant-btn-round': {
            borderRadius: `${buttonHeight / 2}rem`,
        },
        '&.ant-btn:not(:disabled):not(.ant-btn-disabled):hover': {
            color: theme.colors.blue,
            borderColor: theme.colors.blue,
        },
        '&.ant-btn.ant-btn-primary': {
            color: theme.colors.white,
            borderColor: theme.colors.blue,
            backgroundColor: theme.colors.blue,
        },
        '&.ant-btn.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover': {
            color: theme.colors.white,
            borderColor: theme.colors.hoverBlue,
            backgroundColor: theme.colors.hoverBlue,
        },
        '&.ant-btn.ant-btn-dangerous': {
            color: theme.colors.red,
            borderColor: theme.colors.red,
            backgroundColor: theme.colors.white,
        },
        '&.ant-btn.ant-btn-dangerous:not(:disabled):not(.ant-btn-disabled):hover': {
            borderColor: theme.colors.hoverRed,
            color: theme.colors.hoverRed,
        },
        '&.ant-btn.ant-btn-loading:before': {
            maxWidth: '100%',
            maxHeight: '100%',
        },
        '&.ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only)': {
            position: 'inherit',
            paddingLeft: `${buttonHorizontalPadding}rem`,
        },
        '&.ant-btn[disabled]': disabledStyle,
        '&.ant-btn[disabled]:hover': disabledStyle,
        '&.ant-btn.ant-btn-primary[disabled]': primaryDisabledStyle,
        '&.ant-btn.ant-btn-primary[disabled]:hover': primaryDisabledStyle,
    };

    return buttonStyle;
};
