import { useFela } from 'react-fela';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';

import { Tag, Text } from 'modules/ui';
import { hasReadPermission } from 'utilities/permission';
import { Groups } from 'modules/groups';
import { Whitelist } from 'modules/whitelist';

import { SecretSection } from '../SecretSection';
import { SecretFieldSection } from '../SecretFieldSection';
import { useSecretDetailContext } from '../SecretDetailPage';
import { SecretFile } from '../SecretFile';

import { EmptyContentGuard } from './EmptyContentGuard';
import { SecretFieldSectionsByType } from './SecretFieldSectionsByType';

import * as felaRules from './SecretDetailSections.rules';

export const SecretDetailSections = () => {
    const { css } = useFela();

    const secret = useSecretDetailContext();

    const { id, type, tags, file, note, groups, whitelistUsers, userPermissions } = secret;

    const hasRead = hasReadPermission(userPermissions);

    return (
        <div className={css(felaRules.container)}>
            <SecretSection title={`secret.type.${type}`}>
                <SecretFieldSectionsByType secret={secret} />
            </SecretSection>

            <SecretSection title="secret.sections.details.title">
                <EmptyContentGuard>
                    {!isEmpty(tags) && (
                        <SecretFieldSection heading={<FormattedMessage id="secret.tags" />}>
                            <div className={css(felaRules.tags)}>
                                {tags?.map(tag => (
                                    <Tag key={tag}>{tag}</Tag>
                                ))}
                            </div>
                        </SecretFieldSection>
                    )}
                    {file && hasRead ? (
                        <SecretFieldSection heading={<FormattedMessage id="secret.file" />}>
                            <SecretFile file={file} />
                        </SecretFieldSection>
                    ) : null}
                    {note && hasRead ? (
                        <SecretFieldSection heading={<FormattedMessage id="secret.note" />}>
                            <Text preserveNewLines>{note}</Text>
                        </SecretFieldSection>
                    ) : null}
                </EmptyContentGuard>
            </SecretSection>

            <SecretSection title="secret.sections.permissions.title" customStyle={felaRules.rightColumn}>
                <EmptyContentGuard message="secret.sections.permissions.empty">
                    {!isEmpty(groups) ? (
                        <SecretFieldSection heading={<FormattedMessage id="secret.groups" />}>
                            <Groups groups={groups} />
                        </SecretFieldSection>
                    ) : null}
                    {!isEmpty(whitelistUsers) ? (
                        <SecretFieldSection heading={<FormattedMessage id="secret.whitelistUsers" />}>
                            <Whitelist key={id} whitelist={whitelistUsers} />
                        </SecretFieldSection>
                    ) : null}
                </EmptyContentGuard>
            </SecretSection>
        </div>
    );
};
